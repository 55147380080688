import { ReactNode, useState } from 'react'

import { Box, BoxProps, HStack } from '@chakra-ui/react'
import { CellContainer, useCellRef } from '@wwt/custom/react-table'

import { TooltipIconType } from '../Tooltips'
import { FeatureIconMemo, type FeatureIconProps } from './FeatureIcon'
import {
	FeatureButton,
	FeatureClickableButton
} from './components/FeatureButton'

interface FeatureProps extends BoxProps {
	label: string | ReactNode
	isClickable?: boolean
	isExist?: boolean
	onClick?: () => void
	disableIcon?: boolean
	hasTooltip?: boolean
	tooltipImageUrl?: string
	tooltipTitle?: string
	tooltipText?: string
	tooltipIconType?: TooltipIconType
	iconValues: FeatureIconProps
}

export const ComparisonFeature = ({
	label,
	isClickable = false,
	isExist = true,
	onClick,
	disableIcon = false,
	hasTooltip = false,
	tooltipImageUrl,
	tooltipTitle,
	tooltipText,
	tooltipIconType,
	iconValues,
	...props
}: FeatureProps) => {
	const { ref, tooltipOpen } = useCellRef()
	const [parentTooltip, setParentTooltip] = useState<boolean | undefined>(
		undefined
	)

	const basePropsForIcon: FeatureIconProps = {
		groupIconName: iconValues.groupIconName,
		featureIconName: iconValues.featureIconName,
		optionIconName: iconValues.optionIconName,
		width: '1.5rem',
		height: '1.5rem'
	}

	return (
		<Box
			position="relative"
			display="flex"
			{...props}
		>
			{isClickable ? (
				<CellContainer
					label={label}
					tooltipOpen={tooltipOpen}
					padding={0}
				>
					<FeatureClickableButton
						ref={ref}
						basePropsForIcon={basePropsForIcon}
						isExist={isExist}
						onClick={onClick}
						disableIcon={disableIcon}
						noOfLines={{ base: 1, md: 2 }}
						textAlign="center"
						fontSize="inherit"
						w="100%"
						px="1rem"
						py="0.90625rem"
						data-wwt-id="feature-comparison--button"
						hasTooltip={hasTooltip}
						tooltipImageUrl={tooltipImageUrl}
						tooltipTitle={tooltipTitle}
						tooltipText={tooltipText}
						tooltipIconType={tooltipIconType}
					>
						{label}
					</FeatureClickableButton>
				</CellContainer>
			) : (
				<HStack
					color="gray.500"
					w="full"
				>
					{!disableIcon ? (
						<FeatureIconMemo
							{...basePropsForIcon}
							color={isExist ? 'gray.500' : 'brand.100'}
						/>
					) : undefined}
					<CellContainer
						label={label}
						tooltipOpen={parentTooltip ?? tooltipOpen}
					>
						<FeatureButton
							basePropsForIcon={basePropsForIcon}
							hasTooltip={hasTooltip}
							tooltipImageUrl={tooltipImageUrl}
							tooltipTitle={tooltipTitle}
							tooltipText={tooltipText}
							tooltipIconType={tooltipIconType}
							onMouseLeave={() => setParentTooltip(undefined)}
							onMouseEnter={() => setParentTooltip(false)}
							noOfLines={{ base: 1, md: 2 }}
							textAlign="center"
							ref={ref}
							data-wwt-id="feature-comparison--button"
						>
							{label}
						</FeatureButton>
					</CellContainer>
				</HStack>
			)}
		</Box>
	)
}
