import { useMemo } from 'react'

import { Box, BoxProps } from '@chakra-ui/react'

import { SortDebugValue } from '@wwt/core/admin'
import { FeatureType } from '@wwt/entities/api/search-response'
import { Feature } from '@wwt/entities/search-response'
import { capitalizeFirstLetter } from '@wwt/shared/utils/capitalizeFirstLetter'
import { getSelectedOptionInfoFromFeature } from '@wwt/shared/utils/getSelectedOptionInfoFromFeature'

import { TooltipIconType } from '../Tooltips'
import { ComparisonFeature } from './ComparisonFeature'
import { Feature as FeatureComponent } from './Feature'

export interface FeatureByFeatureDataProps extends BoxProps {
	feature: Feature
	onClick?: () => void
	forceIsClickable?: boolean
	showSelectedOption?: boolean
	showFeatureName?: boolean
	disableIcon?: boolean
	hasTooltip?: boolean
	comparisonMode?: boolean
}

export const FeatureByFeatureData = ({
	feature,
	onClick,
	forceIsClickable,
	showSelectedOption = true,
	showFeatureName = true,
	hasTooltip = true,
	disableIcon = false,
	comparisonMode = false,
	...props
}: FeatureByFeatureDataProps) => {
	const { label, tooltipInfo, isExist, optionIconName } = useMemo(() => {
		const selectedOption = getSelectedOptionInfoFromFeature(feature)

		if (!selectedOption || (!showSelectedOption && !showFeatureName)) {
			return {
				label: '-',
				tooltipInfo: {
					title: '-',
					text: '-',
					imageUrl: '-'
				}
			}
		}

		const FeaturePriorityDebug = () => (
			<SortDebugValue
				integrationPriority={feature.integrationPriority}
				wwtPriority={feature.wwtPriority}
			/>
		)

		const OptionPriorityDebug = () => (
			<SortDebugValue
				integrationPriority={selectedOption.integrationPriority}
				wwtPriority={selectedOption.wwtPriority}
			/>
		)

		if (showSelectedOption && showFeatureName) {
			return {
				label: (
					<Box>
						<Box display="inline">{capitalizeFirstLetter(feature.name)}</Box>
						<FeaturePriorityDebug />
						<Box display="inline">
							{': '}
							{capitalizeFirstLetter(selectedOption.name)}
						</Box>
						<OptionPriorityDebug />
					</Box>
				),
				isExist: selectedOption.isExist,
				tooltipInfo: {
					title: capitalizeFirstLetter(selectedOption.name ?? feature.name),
					text: selectedOption.description ?? feature.description,
					imageUrl: selectedOption.imageUrl ?? feature.imageUrl
				},
				optionIconName: selectedOption.iconName
			}
		}

		if (showSelectedOption) {
			return {
				label: (
					<Box display="inline">
						<Box display="inline">
							{capitalizeFirstLetter(selectedOption.name)}
						</Box>
						<OptionPriorityDebug />
					</Box>
				),
				isExist: selectedOption.isExist,
				tooltipInfo: {
					title: capitalizeFirstLetter(selectedOption.name),
					text: selectedOption.description,
					imageUrl: selectedOption.imageUrl
				},
				optionIconName: selectedOption.iconName
			}
		}

		return {
			label: (
				<Box display="inline">
					<Box display="inline">{capitalizeFirstLetter(feature.name)}</Box>
					<FeaturePriorityDebug />
				</Box>
			),
			isExist: selectedOption.isExist,
			tooltipInfo: {
				title: capitalizeFirstLetter(feature.name),
				text: feature.description,
				imageUrl: feature.imageUrl
			}
		}
	}, [feature, showSelectedOption])

	if (comparisonMode) {
		return (
			<ComparisonFeature
				iconValues={{
					groupIconName: feature.group?.iconName,
					featureIconName: feature.iconName,
					optionIconName: optionIconName
				}}
				label={label}
				isClickable={
					forceIsClickable === undefined
						? 'options' in feature && feature.options.length > 1
						: forceIsClickable
				}
				isExist={isExist}
				onClick={onClick}
				hasTooltip={
					hasTooltip &&
					feature.description !== undefined &&
					feature.description.length > 0
				}
				tooltipIconType={
					feature.type === FeatureType.checkIn
						? TooltipIconType.dollar
						: TooltipIconType.default
				}
				tooltipImageUrl={tooltipInfo.imageUrl}
				tooltipTitle={tooltipInfo.title}
				tooltipText={tooltipInfo.text}
				disableIcon={disableIcon}
				{...props}
			/>
		)
	}

	return (
		<FeatureComponent
			iconValues={{
				groupIconName: feature.group?.iconName,
				featureIconName: feature.iconName,
				optionIconName: optionIconName
			}}
			label={label}
			isClickable={
				forceIsClickable === undefined
					? 'options' in feature && feature.options.length > 1
					: forceIsClickable
			}
			isExist={isExist}
			onClick={onClick}
			hasTooltip={
				hasTooltip &&
				feature.description !== undefined &&
				feature.description.length > 0
			}
			tooltipImageUrl={tooltipInfo.imageUrl}
			tooltipTitle={tooltipInfo.title}
			tooltipText={tooltipInfo.text}
			tooltipIconType={
				feature.type === FeatureType.checkIn
					? TooltipIconType.dollar
					: TooltipIconType.default
			}
			disableIcon={disableIcon}
			{...props}
		/>
	)
}
